module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Elliot Sharpe - Portfolio Website","short_name":"Elliot Sharpe Website","start_url":"/","background_color":"#1b1b1b","theme_color":"#1b1b1b","display":"minimal-ui","icon":"src/images/e-icon-29.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b795d6ed36f966f2a7649b589e5613ba"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
